import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withCtx as _withCtx, createBlock as _createBlock, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  class: "section-border"
};
const _hoisted_2 = {
  class: "select-title"
};
const _hoisted_3 = {
  class: "section-border"
};
const _hoisted_4 = {
  class: "select-title"
};
const _hoisted_5 = {
  key: 0,
  class: "section-border"
};
const _hoisted_6 = {
  class: "select-title flex-between"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_divider = _resolveComponent("el-divider");
  const _component_el_radio = _resolveComponent("el-radio");
  const _component_el_radio_group = _resolveComponent("el-radio-group");
  const _component_el_button = _resolveComponent("el-button");
  const _component_base_form = _resolveComponent("base-form");
  return _openBlock(), _createElementBlock("section", null, [_createElementVNode("section", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_el_divider, {
    direction: "vertical"
  }), _createTextVNode(" 统计维度 ")]), _createElementVNode("div", null, [_createVNode(_component_el_radio_group, {
    modelValue: $data.dimension,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $data.dimension = $event)
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$global.statisticalDimension, item => {
      return _openBlock(), _createBlock(_component_el_radio, {
        key: item.value,
        label: item.value
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(item.label), 1)]),
        _: 2
      }, 1032, ["label"]);
    }), 128))]),
    _: 1
  }, 8, ["modelValue"])])]), _createElementVNode("section", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createVNode(_component_el_divider, {
    direction: "vertical"
  }), _createTextVNode(" 筛选条件 ")]), _createVNode(_component_base_form, {
    properties: $props.properties,
    "base-form": $data.chooseForm,
    inline: true,
    width: "100px",
    onGetWarehouse: $options.getWarehouse,
    onGetDate: $options.getDate
  }, {
    default: _withCtx(() => [_createVNode(_component_el_button, {
      type: "primary",
      onClick: $options.queryList
    }, {
      default: _withCtx(() => [_createTextVNode(" 查询 ")]),
      _: 1
    }, 8, ["onClick"]), _createVNode(_component_el_button, {
      onClick: $options.resetForm
    }, {
      default: _withCtx(() => [_createTextVNode(" 重置 ")]),
      _: 1
    }, 8, ["onClick"])]),
    _: 1
  }, 8, ["properties", "base-form", "onGetWarehouse", "onGetDate"])]), $props.visible ? (_openBlock(), _createElementBlock("section", _hoisted_5, [_createElementVNode("div", _hoisted_6, [_createElementVNode("div", null, [_createVNode(_component_el_divider, {
    direction: "vertical"
  }), _createTextVNode(" 图表展示")]), _createVNode(_component_el_button, {
    type: "primary",
    onClick: $options.exportStatistics
  }, {
    default: _withCtx(() => [_createTextVNode(" 导出 ")]),
    _: 1
  }, 8, ["onClick"])]), _renderSlot(_ctx.$slots, "default")])) : _createCommentVNode("", true)]);
}