import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue";
const _hoisted_1 = {
  style: {
    "margin": "20px"
  }
};
const _hoisted_2 = {
  class: "table-title"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_average_chart = _resolveComponent("average-chart");
  const _component_el_table_column = _resolveComponent("el-table-column");
  const _component_el_button = _resolveComponent("el-button");
  const _component_el_table = _resolveComponent("el-table");
  const _component_statistics_table = _resolveComponent("statistics-table");
  const _component_base_option = _resolveComponent("base-option");
  const _component_common_statistics = _resolveComponent("common-statistics");
  return _openBlock(), _createElementBlock("section", null, [_createVNode(_component_common_statistics, {
    properties: _ctx.$global.averageChooseOptions,
    type: "average",
    visible: _ctx.$store.state.statistics.averageTableVisible,
    onGetForm: $options.getForm
  }, {
    default: _withCtx(() => [_createVNode(_component_average_chart, {
      data: $data.averageStatistics,
      empty: $data.emptyList
    }, null, 8, ["data", "empty"]), _createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, _toDisplayString(`${$data.firstLabel}时效合格率统计表`), 1), $data.averageList.length ? (_openBlock(), _createBlock(_component_el_table, {
      key: 0,
      data: $data.averageList,
      border: ""
    }, {
      default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.listFields, item => {
        return _openBlock(), _createElementBlock(_Fragment, {
          key: item.prop
        }, [item.prop !== 'count' ? (_openBlock(), _createBlock(_component_el_table_column, {
          key: 0,
          label: item.label,
          align: "center"
        }, {
          default: _withCtx(scope => [_createElementVNode("span", {
            style: _normalizeStyle(scope.row[item.prop].indexOf('%') > -1 ? {
              color: $options.changeRateColor(scope.row[item.prop])
            } : {})
          }, _toDisplayString(scope.row[item.prop]), 5)]),
          _: 2
        }, 1032, ["label"])) : (_openBlock(), _createBlock(_component_el_table_column, {
          key: 1,
          label: item.label,
          align: "center"
        }, {
          default: _withCtx(scope => [_createVNode(_component_el_button, {
            text: "",
            type: "primary",
            onClick: $event => $options.viewSingleAverage(scope.row.id, scope.row.count)
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(scope.row.count), 1)]),
            _: 2
          }, 1032, ["onClick"])]),
          _: 2
        }, 1032, ["label"]))], 64);
      }), 128))]),
      _: 1
    }, 8, ["data"])) : _createCommentVNode("", true)]), _createVNode(_component_base_option, {
      modelValue: $data.averageVisible,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $data.averageVisible = $event),
      title: `${$data.title}平均时效统计`
    }, {
      default: _withCtx(() => [_createVNode(_component_statistics_table, {
        table: $data.table,
        name: $data.lastLabel
      }, null, 8, ["table", "name"])]),
      _: 1
    }, 8, ["modelValue", "title"])]),
    _: 1
  }, 8, ["properties", "visible", "onGetForm"])]);
}