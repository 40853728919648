import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-16e0f3cc"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  key: 0,
  style: {
    "color": "#ea1d1d",
    "margin": "10px"
  }
};
const _hoisted_2 = {
  class: "chart-layout"
};
const _hoisted_3 = ["id"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_average_table = _resolveComponent("average-table");
  const _component_el_scrollbar = _resolveComponent("el-scrollbar");
  const _directive_loading = _resolveDirective("loading");
  return _openBlock(), _createElementBlock("div", null, [$props.empty.length ? (_openBlock(), _createElementBlock("div", _hoisted_1, " 提示：" + _toDisplayString($data.emptyStr), 1)) : _createCommentVNode("", true), _ctx.$store.state.statistics.averageStatistics.length ? (_openBlock(), _createBlock(_component_el_scrollbar, {
    key: 1
  }, {
    default: _withCtx(() => [_withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$store.state.statistics.averageStatistics, (item, index) => {
      return _openBlock(), _createElementBlock("section", {
        key: index,
        style: {
          "margin-left": "20px"
        }
      }, [_createElementVNode("div", {
        id: `chart${index + 1}`,
        class: "chart"
      }, null, 8, _hoisted_3), _createElementVNode("div", null, _toDisplayString(item.table.name), 1), _createVNode(_component_el_scrollbar, {
        style: {
          "height": "auto !important"
        }
      }, {
        default: _withCtx(() => [_createVNode(_component_average_table, {
          list: item.table.data
        }, null, 8, ["list"])]),
        _: 2
      }, 1024)]);
    }), 128))])), [[_directive_loading, _ctx.$store.state.statistics.averageLoading]])]),
    _: 1
  })) : _createCommentVNode("", true)]);
}