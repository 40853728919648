import { cache, setYearOption, getWeek, checkStayTime } from '../../utils/index.js';
import { Search, Operation } from '@element-plus/icons-vue';
export default {
  components: {
    Search,
    Operation
  },
  props: {
    properties: {
      type: Array,
      default: null
    },
    baseForm: {
      type: Object,
      default: null
    },
    inline: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: ''
    },
    baseRules: {
      type: Object,
      default: null
    }
  },
  emits: ['get-info', 'get-warehouse', 'get-date', 'search-waybill-no'],
  data() {
    return {
      timeout: null,
      form: this.baseForm,
      optionLoading: false,
      remoteLoading: true,
      warehouse: [],
      option: null,
      date: new Date(),
      showYear: false,
      popoverVisible: false,
      operationIconColor: 'inherit'
    };
  },
  watch: {
    baseForm(val) {
      this.form = val;
    },
    'form.shipping_time_unit': {
      handler(val) {
        this.option = [];
        if (val === 'w') {
          this.form.year = this.date.getFullYear();
          this.getWeek();
          this.showYear = false;
        } else if (val === 'y') {
          this.option = setYearOption();
          this.getCurrentYear();
          this.showYear = true;
        } else {
          this.option = 12;
          this.form.year = this.date.getFullYear();
          this.getCurrentMonth();
          this.showYear = false;
        }
      },
      immediate: true,
      deep: true
    },
    'form.waybill_no': {
      handler(val) {
        if (val) {
          this.form.multiple_waybill_no = '';
        }
      },
      immediate: true,
      deep: true
    },
    'form.multiple_waybill_no': {
      handler(val) {
        if (val) {
          this.form.waybill_no = '';
        }
      },
      immediate: true,
      deep: true
    }
  },
  mounted() {
    this.properties.forEach(item => {
      if (item.type === 'select' && item.prop !== 'shipping_time_unit') {
        this.getOption(item.prop);
      }
      if (item.prop === 'shipping_time_unit') {
        this.form.shipping_time_unit = 'w';
        this.getWeek();
      }
    });
  },
  methods: {
    checkStayTime,
    async getWeek() {
      getWeek(this.form.year).then(res => {
        if (res) {
          this.option = res.week_num;
          this.form.start_shipping_time = res.current_week;
          this.form.end_shipping_time = res.current_week;
        }
      });
    },
    getCurrentMonth() {
      let month = this.date.getMonth() + 1;
      this.form.start_shipping_time = month;
      this.form.end_shipping_time = month;
    },
    getCurrentYear() {
      let year = this.date.getFullYear();
      this.form.start_shipping_time = year;
      this.form.end_shipping_time = year;
      this.form.year = '';
    },
    getOptionObj(prop) {
      return this.properties.find(item => {
        return item.prop === prop;
      });
    },
    async getOption(prop) {
      this.optionLoading = true;
      let selectObj = this.getOptionObj(prop);
      try {
        if (prop !== 'exception_handling' && prop !== 'parcel_type' && prop !== 'year') {
          let newProp = prop.replace('_id', '');
          let arr = [];
          arr = newProp.split('_');
          // 将获取到的属性的首字母大写
          for (let i = 0; i < arr.length; i++) {
            arr[i] = arr[i].slice(0, 1).toUpperCase() + arr[i].slice(1).toLowerCase();
          }
          if (!cache(newProp)) {
            await this.$store.dispatch(`get${arr.join('')}`);
          }
          selectObj.options = JSON.parse(cache(newProp));
        } else if (prop !== 'year') {
          if (!cache(prop)) {
            await this.$store.dispatch('getSystemParameter');
          }
          selectObj.options = JSON.parse(cache(prop));
        } else {
          selectObj.options = setYearOption();
        }
        this.optionLoading = false;
      } catch (err) {
        this.optionLoading = false;
        return;
      }
    },
    async getSkuOrOrderOption(label, str, query, prop, fn) {
      let selectObj = this.getOptionObj(prop);
      selectObj.options = [];
      try {
        await this.$store.dispatch(fn, {
          params: {
            [label]: query
          }
        });
        selectObj.options = this.$store.state[str];
        this.remoteLoading = false;
      } catch (err) {
        return;
      }
    },
    remoteMethod(query, prop, type) {
      if (type === 'remote') {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          if (query) {
            this.remoteLoading = true;
            try {
              if (prop === 'sku_id') {
                this.getSkuOrOrderOption('name', 'sku', query, prop, 'getSku');
              } else {
                this.getSkuOrOrderOption('order_no', 'order', query, prop, 'getOrder');
              }
            } catch (err) {
              return;
            }
          } else {
            let selectObj = this.getOptionObj(prop);
            selectObj.options = [];
          }
        }, 500);
      }
    },
    async getRelatedInfo(id, flag, prop, type) {
      if (prop === 'warehouse_area_id' || prop === 'oversea_location_id') {
        try {
          let params = {
            oversea_location_id: this.form.oversea_location_id.join(','),
            warehouse_area_id: this.form.warehouse_area_id.join(',')
          };
          await this.$store.dispatch('getWarehouse', {
            params
          });
          let selectObj = this.getOptionObj('warehouse_id');
          selectObj.options = this.$store.state.warehouse;
          this.warehouse = selectObj.options.map(item => {
            return item.id;
          });
          if (selectObj.options.length === 0) {
            this.warehouse = -1;
          }
          if (this.form.warehouse_area_id.length === 0 && this.form.oversea_location_id.length === 0) {
            this.warehouse = [];
          }
          this.$emit('get-warehouse', this.warehouse);
        } catch (err) {
          return;
        }
      } else if (type === 'remote') {
        if (!flag) {
          this.$emit('get-info', id);
        }
      } else if (prop === 'year') {
        switch (this.form.shipping_time_unit) {
          case 'w':
            this.getWeek();
            break;
          case 'm':
            this.getCurrentMonth();
            break;
          default:
        }
      }
    },
    changeDate(val, prop) {
      this.$emit('get-date', {
        val,
        prop
      });
    },
    disabled(prop, disabled) {
      if (prop === 'year' && this.showYear || prop === 'oversea_location_id' && disabled || prop === 'warehouse_area_id' && disabled) {
        return true;
      }
    },
    searchWaybillNo(type, prop) {
      this.$emit('search-waybill-no', {
        [prop]: this.form[prop],
        waybill_no_query_type: type
      });
    },
    clearMultipleWaybillNo() {
      this.form.multiple_waybill_no = '';
    },
    changePopoverState(visible, color) {
      this.popoverVisible = visible;
      this.operationIconColor = color;
    }
  }
};