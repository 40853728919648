import CommonStatistics from '../common-statistics.vue';
import AverageChart from './average-chart.vue';
import StatisticsTable from '../statistics-table.vue';
import { changeRateColor } from '../../../../../utils/index.js';
export default {
  components: {
    CommonStatistics,
    AverageChart,
    StatisticsTable
  },
  data() {
    return {
      form: {},
      averageStatistics: [],
      emptyList: [],
      averageList: [],
      listFields: [],
      averageVisible: false,
      table: {},
      firstLabel: [],
      lastLabel: [],
      title: '',
      dimension: null
    };
  },
  mounted() {
    this.$store.commit('statistics/setAverageTableVisible', false);
  },
  methods: {
    changeRateColor,
    async getForm(obj) {
      this.form = obj.params;
      this.dimension = obj.params.statistical_dimension;
      if (obj.request) {
        if (this.form.shipping_time_unit !== 'y' && !this.form.year) {
          this.$message.warning('发货时间在选择按周/按月时，年份是必填的');
        } else if (!this.form.shipping_time_unit) {
          this.$message.warning('发货时间单位为必填项');
        } else if (!this.form.start_shipping_time || !this.form.end_shipping_time) {
          this.$message.warning('发货时间区间为必填项');
        } else {
          this.$store.commit('statistics/setAverageLoading', true);
          try {
            await this.$store.dispatch('statistics/getAverageStatistics', {
              params: obj.params
            });
            this.averageStatistics = this.$store.state.statistics.averageStatistics;
            this.emptyList = this.$store.state.statistics.averageEmptyList;
            this.averageList = this.$store.state.statistics.averageList;
            this.firstLabel = this.$global.statisticalDimension.find(item => {
              return item.value === this.dimension;
            }).label;
            this.lastLabel = this.$global.titleMap[this.dimension];
            this.listFields = [{
              label: this.firstLabel,
              prop: 'name'
            }].concat(this.$global.commonListFields);
            this.listFields = this.listFields.concat([{
              label: this.lastLabel,
              prop: 'count'
            }]);
            if (!this.averageStatistics.length && !this.emptyList.length && !this.averageList.length) {
              this.$store.commit('statistics/setAverageTableVisible', false);
              this.$message.warning('当前查询结果为空');
            }
            this.$store.commit('statistics/setAverageLoading', false);
          } catch (err) {
            this.$store.commit('statistics/setAverageLoading', false);
            return;
          }
        }
      }
    },
    async viewSingleAverage(id, count) {
      if (count !== '-' && count !== 0) {
        let params = this.form;
        params.statistical_dimension_id = id;
        try {
          await this.$store.dispatch('statistics/getAverageDetail', {
            params
          });
          this.averageDetail = this.$store.state.statistics.averageDetail;
          this.table = {
            tableData: this.averageDetail,
            tableFields: [{
              label: `${this.lastLabel}名称`,
              prop: 'name'
            }].concat(this.$global.commonListFields)
          };
          this.title = this.lastLabel.substring(0, this.lastLabel.length - 2);
          this.averageVisible = true;
        } catch (err) {
          return;
        }
      } else {
        this.$message.warning('当前无数据可查看');
      }
    }
  }
};