import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_table_column = _resolveComponent("el-table-column");
  const _component_el_table = _resolveComponent("el-table");
  const _component_el_scrollbar = _resolveComponent("el-scrollbar");
  return _openBlock(), _createBlock(_component_el_scrollbar, {
    height: "400px"
  }, {
    default: _withCtx(() => [_createVNode(_component_el_table, {
      data: $props.table.tableData,
      border: ""
    }, {
      default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.table.tableFields, item => {
        return _openBlock(), _createBlock(_component_el_table_column, {
          key: item.prop,
          label: item.label,
          align: "center"
        }, {
          default: _withCtx(scope => [_createElementVNode("span", {
            style: _normalizeStyle(scope.row[item.prop].indexOf('%') > -1 ? {
              color: $options.changeRateColor(scope.row[item.prop])
            } : {})
          }, _toDisplayString(scope.row[item.prop]), 5)]),
          _: 2
        }, 1032, ["label"]);
      }), 128))]),
      _: 1
    }, 8, ["data"])]),
    _: 1
  });
}