import { handleDateRange, defaultTime } from '../../../../utils/index.js';
export default {
  props: {
    properties: {
      type: Array,
      default: null
    },
    visible: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: ''
    }
  },
  emits: ['get-form'],
  data() {
    return {
      chooseForm: {},
      dimension: null,
      warehouse: [],
      defaultOption: null
    };
  },
  mounted() {
    if (this.type === 'daily') {
      this.chooseForm.shipping_time = defaultTime(31);
    }
  },
  methods: {
    getDate(obj) {
      this.chooseForm.shipping_time = obj.val;
      this.$store.commit('statistics/setDateChange', true);
    },
    handleChoose() {
      if (this.type === 'daily') {
        if (!this.$store.state.statistics.isDateChange) {
          this.chooseForm.shipping_time = defaultTime(31);
        }
        handleDateRange(this.chooseForm, 'shipping_time');
      }
      let params = JSON.parse(JSON.stringify(this.chooseForm));
      if (this.chooseForm.warehouse_id.length === 0) {
        params.warehouse_id = this.warehouse;
      }
      for (let i in params) {
        if (Array.isArray(params[i]) && i !== 'shipping_time') {
          params[i] = params[i].join(',');
        }
      }
      return params;
    },
    getWarehouse(val) {
      this.warehouse = val;
    },
    queryList() {
      if (this.dimension) {
        let params = this.handleChoose();
        delete params.shipping_time;
        params.statistical_dimension = this.dimension;
        this.$emit('get-form', {
          params,
          request: true
        });
      } else {
        this.$message.warning('请选择统计维度');
      }
    },
    resetForm() {
      this.chooseForm = {};
      this.dimension = null;
      let params = this.chooseForm;
      params.dimension = null;
      this.chooseForm = {
        warehouse_id: []
      };
      this.warehouse = [];
      if (this.type === 'daily') {
        this.chooseForm.shipping_time = defaultTime(31);
        this.$store.commit('statistics/setDateChange', false);
      } else {
        this.chooseForm.shipping_time_unit = 'w';
      }
      this.$emit('get-form', {
        params,
        request: false
      });
      if (this.type === 'daily') {
        this.$store.commit('statistics/setDailyTableVisible', false);
      } else {
        this.$store.commit('statistics/setAverageTableVisible', false);
      }
    },
    async exportStatistics() {
      let body = this.handleChoose();
      delete body.shipping_time;
      body.statistical_dimension = this.dimension;
      try {
        if (this.type === 'daily') {
          await this.$store.dispatch('statistics/exportDailyStatistics', body);
        } else {
          await this.$store.dispatch('statistics/exportAverageStatistics', body);
        }
      } catch (err) {
        return;
      }
    }
  }
};