import { transposeArray, changeRateColor } from '../../../../../utils/index.js';
export default {
  props: {
    list: {
      type: Array,
      default: null
    }
  },
  data() {
    return {
      originData: this.list,
      originTitle: this.$global.averageRate
    };
  },
  watch: {
    list: {
      handler(val) {
        this.originData = val;
        this.transposeArray();
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    changeRateColor,
    transposeArray() {
      this.originData = transposeArray(this.originData, this.originTitle);
    }
  }
};