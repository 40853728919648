import { changeRateColor } from '../../../../utils/index.js';
export default {
  props: {
    table: {
      type: Object,
      default: null
    }
  },
  methods: {
    changeRateColor
  }
};